<!--
	This a demo version of the CalenderList doctype,
	which will be used by Kultunaut, and can be safely
	removed AFTER the actual CalendarList have been
	implemented - which is, well, not yet.
-->
<template>
	<article class="d-calendar-list-custom">
		<PageBreadcrumb :items="breadcrumb" class="mt-lg" />

		<!-- Kultunauts part starts here -->
		<div class="d-calendar-list-custom__contents">
			<div class="d-calendar-list-custom__top">
				<!-- Top -->
				<div class="d-calendar-list-custom__top-title">
					<h1>Find arrangementer</h1>

					<div class="d-calendar-list-custom__top-buttons">
						<!-- todo, maybe for me: replace href with link to place overview  -->
						<a href="#" class="d-calendar-list-custom__button">
							<span v-text="'Find foreninger'"></span>
							<svg viewBox="0 0 15 15" fill="none">
								<path
									d="M14 14L9 9"
									stroke="currentColor"
									stroke-width="2"
									stroke-miterlimit="10"
								/>
								<path
									d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
									stroke="currentColor"
									stroke-width="2"
									stroke-miterlimit="10"
								/>
							</svg>
						</a>

						<!-- todo: replace href with link to create event  -->
						<a href="#" class="d-calendar-list-custom__button">
							<span v-text="'Opret arrangement'"></span>
							<svg viewBox="0 0 15 15" fill="none">
								<path
									d="M7.5 15.0L7.5 0.0"
									stroke="currentColor"
									stroke-width="2"
								/>
								<path
									d="M0.0 7.5L15.0 7.5"
									stroke="currentColor"
									stroke-width="2"
								/>
							</svg>
						</a>
					</div>
				</div>

				<!-- Search Filters: General -->
				<!-- todo: search on enter or click on search-icon-button -->
				<div class="d-calendar-list-custom__filters-general">
					<div class="d-calendar-list-custom__filters-search">
						<input
							type="search"
							placeholder="Søg efter arrangement"
						/>

						<!-- note: this button -->
						<button @click.prevent>
							<svg
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14 14L9 9"
									stroke="currentColor"
									stroke-width="2"
									stroke-miterlimit="10"
								/>
								<path
									d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
									stroke="currentColor"
									stroke-width="2"
									stroke-miterlimit="10"
								/>
							</svg>
						</button>
					</div>

					<!-- todo: add accessible date-picker, and correct icons and label -->
					<div class="d-calendar-list-custom__filters-date">
						<div class="d-calendar-list-custom__filters-date-input">
							Søg efter dato
						</div>
					</div>
				</div>

				<!-- Search Filters: Categories -->
				<div class="d-calendar-list-custom__filters-categories">
					<!--
						Desktop
					-->
					<div
						:class="[
							'd-calendar-list-custom__filters-categories-inner',
							'd-calendar-list-custom__filters-categories-inner--desktop',
						]"
					>
						<div
							class="
								d-calendar-list-custom__filters-category-container
							"
						>
							<!--
								todo: create a button for each category, and activate category on click and search.

								Furthermore the 'd-calendar-list-custom__filters-category--active' class should be added to active categories,
								in order to giv the green background.
							-->
							<button
								v-for="(category, index) in categories"
								:key="index"
								:class="[
									'd-calendar-list-custom__filters-category',
									{
										'd-calendar-list-custom__filters-category--active':
											category.active,
									},
								]"
								@click.prevent
							>
								<!-- todo: replace the icon and label below with the correct ones for the category -->
								<Component :is="category.icon" />
								<span v-text="category.label"></span>
							</button>
						</div>
					</div>

					<!--
						Mobile
						Note: this is needed because the categories on mobile
						go on two lines.
					-->
					<div
						:class="[
							'd-calendar-list-custom__filters-categories-inner',
							'd-calendar-list-custom__filters-categories-inner--mobile',
						]"
					>
						<div
							class="
								d-calendar-list-custom__filters-category-container
							"
						>
							<!-- First line should contain the first half of categories, ie. 0 to floor(n / 2).-->
							<div>
								<button
									v-for="(
										category, index
									) in categories.slice(
										0,
										Math.floor(categories.length / 2)
									)"
									:key="index"
									:class="[
										'd-calendar-list-custom__filters-category',
										{
											'd-calendar-list-custom__filters-category--active':
												category.active,
										},
									]"
									@click.prevent
								>
									<Component :is="category.icon" />
									<span v-text="category.label"></span>
								</button>
							</div>

							<!-- Second line should contain the last half of categories, ie. floor(n / 2) to n.-->
							<div>
								<button
									v-for="(
										category, index
									) in categories.slice(
										Math.floor(categories.length / 2),
										categories.length
									)"
									:key="index"
									:class="[
										'd-calendar-list-custom__filters-category',
										{
											'd-calendar-list-custom__filters-category--active':
												category.active,
										},
									]"
									@click.prevent
								>
									<Component :is="category.icon" />
									<span v-text="category.label"></span>
								</button>
							</div>
						</div>
					</div>

					<!-- Gradient + buttons -->
					<div
						:class="[
							'd-calendar-list-custom__swiper-button',
							'd-calendar-list-custom__swiper-button--left',
						]"
					>
						<button
							:class="[
								'd-calendar-list-custom__swiper-button-inner',
								'd-calendar-list-custom__swiper-button-inner--left',
							]"
						>
							<svg
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 24C16 15.1811 23.1811 8 32 8"
									stroke="currentColor"
									stroke-width="4"
									stroke-miterlimit="10"
								/>
								<path
									d="M0 8C8.8189 8 16 15.1811 16 24"
									stroke="currentColor"
									stroke-width="4"
									stroke-miterlimit="10"
								/>
							</svg>
						</button>
					</div>

					<div
						:class="[
							'd-calendar-list-custom__swiper-button',
							'd-calendar-list-custom__swiper-button--right',
						]"
					>
						<button
							:class="[
								'd-calendar-list-custom__swiper-button-inner',
								'd-calendar-list-custom__swiper-button-inner--right',
							]"
						>
							<svg
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 24C16 15.1811 23.1811 8 32 8"
									stroke="currentColor"
									stroke-width="4"
									stroke-miterlimit="10"
								/>
								<path
									d="M0 8C8.8189 8 16 15.1811 16 24"
									stroke="currentColor"
									stroke-width="4"
									stroke-miterlimit="10"
								/>
							</svg>
						</button>
					</div>
				</div>

				<div class="d-calendar-list-custom__results">
					<!-- todo: replace dummy text with correct number and date -->
					<div class="d-calendar-list-custom__results-indicator">
						Viser <b>730</b> arrangementer fra/efter 27. jul. 2022
					</div>

					<div class="d-calendar-list-custom__results-cards">
						<!-- todo: repeat below for each card -->
						<CalendarListCustomDemoCard v-for="n in 12" :key="n" />
					</div>

					<!-- todo: update links and numbers -->
					<div class="d-calendar-list-custom__bottom">
						<div class="d-calendar-list-custom__pages">
							<div
								:class="[
									'd-calendar-list-custom__pages-prev',
									'd-calendar-list-custom__pages-prev--disabled',
								]"
							>
								<svg
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16 24C16 15.1811 23.1811 8 32 8"
										stroke="currentColor"
										stroke-width="4"
										stroke-miterlimit="10"
									/>
									<path
										d="M0 8C8.8189 8 16 15.1811 16 24"
										stroke="currentColor"
										stroke-width="4"
										stroke-miterlimit="10"
									/>
								</svg>
							</div>

							<div
								:class="[
									'd-calendar-list-custom__page',
									'd-calendar-list-custom__page--active',
								]"
							>
								1
							</div>
							<a href="#">2</a>
							<a href="#">3</a>
							<a href="#">4</a>

							<div class="d-calendar-list-custom__pages-spacer">
								...
							</div>

							<a href="#">28</a>

							<a
								href="#"
								:class="['d-calendar-list-custom__pages-next']"
							>
								<svg
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16 24C16 15.1811 23.1811 8 32 8"
										stroke="currentColor"
										stroke-width="4"
										stroke-miterlimit="10"
									/>
									<path
										d="M0 8C8.8189 8 16 15.1811 16 24"
										stroke="currentColor"
										stroke-width="4"
										stroke-miterlimit="10"
									/>
								</svg>
							</a>
						</div>

						<div class="d-calendar-list-custom__bottom-buttons">
							<!-- todo, maybe for me: replace href with link to place overview  -->
							<a href="#" class="d-calendar-list-custom__button">
								<span v-text="'Find foreninger'"></span>
								<svg viewBox="0 0 15 15" fill="none">
									<path
										d="M14 14L9 9"
										stroke="currentColor"
										stroke-width="2"
										stroke-miterlimit="10"
									/>
									<path
										d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
										stroke="currentColor"
										stroke-width="2"
										stroke-miterlimit="10"
									/>
								</svg>
							</a>

							<!-- todo: replace href with link to create event  -->
							<a href="#" class="d-calendar-list-custom__button">
								<span v-text="'Opret arrangement'"></span>
								<svg viewBox="0 0 15 15" fill="none">
									<path
										d="M7.5 15.0L7.5 0.0"
										stroke="currentColor"
										stroke-width="2"
									/>
									<path
										d="M0.0 7.5L15.0 7.5"
										stroke="currentColor"
										stroke-width="2"
									/>
								</svg>
							</a>
						</div>

						<img
							class="d-calendar-page-custom__kultunaut-logo"
							src="/kultunaut-logo.png"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- Kultunauts part ends here -->
	</article>
</template>

<script>
import CalendarListCustomDemoCard from './CalendarListCustomDemoCard.vue';

import SvgKultunautTheater from '~/assets/svgs/icon-kultunaut-theater.svg?inline';
import SvgKultunautMusic from '~/assets/svgs/icon-kultunaut-music.svg?inline';
import SvgKultunautMovie from '~/assets/svgs/icon-kultunaut-movie.svg?inline';
import SvgKultunautExhibition from '~/assets/svgs/icon-kultunaut-exhibition.svg?inline';
import SvgKultunautSenior from '~/assets/svgs/icon-kultunaut-senior.svg?inline';
import SvgKultunautChildren from '~/assets/svgs/icon-kultunaut-children.svg?inline';
import SvgKultunautNature from '~/assets/svgs/icon-kultunaut-nature.svg?inline';
import SvgKultunautMotion from '~/assets/svgs/icon-kultunaut-motion.svg?inline';
import SvgKultunautCourses from '~/assets/svgs/icon-kultunaut-courses.svg?inline';
import SvgKultunautOther from '~/assets/svgs/icon-kultunaut-other.svg?inline';
import PageBreadcrumb from '~/components/shared/PageBreadcrumb';

export default {
	name: 'CalendarListCustomDemo',

	components: {
		SvgKultunautTheater,
		SvgKultunautMusic,
		SvgKultunautMovie,
		SvgKultunautExhibition,
		SvgKultunautSenior,
		SvgKultunautChildren,
		SvgKultunautNature,
		SvgKultunautMotion,
		SvgKultunautCourses,
		SvgKultunautOther,
		PageBreadcrumb,
		CalendarListCustomDemoCard,
	},

	inheritAttrs: false,

	props: {
		breadcrumb: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			categories: [
				{ label: 'Teater', icon: 'SvgKultunautTheater' },
				{ label: 'Musik', icon: 'SvgKultunautMusic', active: true },
				{ label: 'Film', icon: 'SvgKultunautMovie' },
				{ label: 'Udstilling', icon: 'SvgKultunautExhibition' },
				{ label: 'Senior', icon: 'SvgKultunautSenior' },
				{ label: 'Børn', icon: 'SvgKultunautChildren' },
				{ label: 'Natur', icon: 'SvgKultunautNature', active: true },
				{ label: 'Motion', icon: 'SvgKultunautMotion' },
				{ label: 'Foredrag og kursur', icon: 'SvgKultunautCourses' },
				{ label: 'Andet', icon: 'SvgKultunautOther' },
			],
		};
	},

	mounted() {
		const scrollers = document.querySelectorAll(
			'.d-calendar-list-custom__filters-categories-inner'
		);

		const buttons = document.querySelectorAll(
			'.d-calendar-list-custom__swiper-button-inner'
		);

		[...scrollers].forEach((scroller) => {
			scroller?.addEventListener('scroll', this.onCategoriesScroll);
			scroller?.addEventListener('resize', this.onCategoriesScroll);
			this.onCategoriesScroll({ target: scroller });
		});

		[...buttons].forEach((button) => {
			button.addEventListener('click', this.onCategoriesButtonClick);
		});
	},

	beforeDestroy() {
		const scrollers = document.querySelectorAll(
			'.d-calendar-list-custom__filters-categories-inner'
		);

		const buttons = document.querySelectorAll(
			'.d-calendar-list-custom__swiper-button-inner'
		);

		[...scrollers].forEach((scroller) => {
			scroller?.removeEventListener('scroll', this.onCategoriesScroll);
			scroller?.removeEventListener('resize', this.onCategoriesScroll);
		});

		[...buttons].forEach((button) => {
			button.removeEventListener('click', this.onCategoriesButtonClick);
		});
	},

	methods: {
		onCategoriesScroll({ target }) {
			const modifier = window.innerWidth < 1024 ? 'mobile' : 'desktop';

			const left = document.querySelector(
				'.d-calendar-list-custom__swiper-button--left'
			);
			const right = document.querySelector(
				'.d-calendar-list-custom__swiper-button--right'
			);

			if (left && right) {
				const container = document.querySelector(
					`.d-calendar-list-custom__filters-categories-inner--${modifier} ` +
						'.d-calendar-list-custom__filters-category-container'
				);

				const { scrollLeft } = target;
				const { width: sw } = target.getBoundingClientRect();
				const { width: cw } = container.getBoundingClientRect();

				const canScrollLeft = scrollLeft > 4;
				const canScrollRight = scrollLeft + sw < cw - 4;

				left.classList[canScrollLeft ? 'add' : 'remove']('active');
				right.classList[canScrollRight ? 'add' : 'remove']('active');
			}
		},

		onCategoriesButtonClick({ target }) {
			const modifier = window.innerWidth < 1024 ? 'mobile' : 'desktop';
			const scroller = document.querySelector(
				`.d-calendar-list-custom__filters-categories-inner--${modifier}`
			);

			const left = target.classList.contains(
				'd-calendar-list-custom__swiper-button-inner--left'
			);

			scroller.scrollTo({
				left: scroller.scrollLeft + 220 * (left ? -1 : 1),
				behavior: 'smooth',
			});
		},
	},
};
</script>

<style lang="postcss">
/**
    General
 */
.d-calendar-list-custom :where(h1) {
	@apply font-darker-grotesque font-bold text-h1;
}

.d-calendar-list-custom :where(.d-calendar-list-custom__button) {
	@apply relative inline-flex items-center gap-x-16;
	@apply border-2 border-text rounded-full px-24 py-6;
	@apply font-darker-grotesque font-semibold text-label-card;
	@apply overflow-hidden;

	& > span {
		@apply mb-4;
	}

	& > svg {
		@apply w-12 text-text transform;
		@apply duration-500 ease-smooth-out;
	}

	&::before {
		@apply absolute top-0 left-0 w-full h-full;
		@apply rounded-full bg-black bg-opacity-5;
		@apply duration-500 ease-smooth-out;
		@apply transform -translate-x-full;
		z-index: -1;
		content: '';
	}

	&:hover::before {
		@apply translate-x-0 bg-opacity-10;
	}

	&:hover > svg {
		@apply translate-x-8;
	}
}

.d-calendar-list-custom :where(input[type='text']),
.d-calendar-list-custom :where(input[type='search']) {
	@apply rounded-full border-2 border-text;
	@apply font-darker-grotesque font-medium text-input;
	@apply h-48 px-lg pb-6;
}

@screen >=1024 {
	.d-calendar-list-custom :where(input[type='text']),
	.d-calendar-list-custom :where(input[type='search']) {
		@apply h-60;
	}
}

/**
    Content Specific
 */
.d-calendar-list-custom__contents {
	@apply mt-4xl;
}

.d-calendar-list-custom__top-title {
	@apply flex justify-between items-end;

	& > div {
		@apply space-x-12;
	}
}

/**
	Buttons
 */
.d-calendar-list-custom__top-buttons {
	display: none;
}

@screen >=1024 {
	.d-calendar-list-custom__top-buttons {
		display: block;
	}
}

.d-calendar-list-custom__bottom-buttons {
	@apply flex-col space-y-12 my-64;

	& > * {
		@apply w-full justify-between;
	}
}

@screen >=1024 {
	.d-calendar-list-custom__bottom-buttons {
		display: none;
	}
}

/**
	Search Filters
	General
 */
.d-calendar-list-custom__filters-general {
	@apply flex flex-col gap-x-layout-gutter gap-y-12 mt-2xl -mx-10;
}

@screen >=1024 {
	.d-calendar-list-custom__filters-general {
		@apply flex-row;
	}
}

.d-calendar-list-custom__filters-search {
	@apply w-full relative;

	& > input {
		@apply w-full;
	}

	& > button {
		@apply absolute top-1/2 right-lg w-16;
		@apply transform -translate-y-1/2;

		& > svg {
			@apply w-full;
		}
	}
}

@screen >=1024 {
	.d-calendar-list-custom__filters-search {
		@apply w-7/12col flex-shrink-0;
	}
}

.d-calendar-list-custom__filters-date {
	@apply w-full relative;

	& > .d-calendar-list-custom__filters-date-input {
		@apply flex items-center h-48 px-lg pb-6;
		@apply rounded-full border-2 border-text bg-text text-white;
		@apply font-darker-grotesque font-medium text-input-dropdown;
	}
}

@screen >=1024 {
	.d-calendar-list-custom__filters-date-input {
		@apply h-60 !important;
	}
}

/**
	Search Filters
	Categories
 */
.d-calendar-list-custom__filters-categories {
	@apply relative mt-md -mx-10;
}

@screen >=1440 {
	.d-calendar-list-custom__filters-categories {
		@apply -mx-64;
	}
}

.d-calendar-list-custom__filters-categories-inner--desktop {
	@apply w-full overflow-x-scroll;

	& > .d-calendar-list-custom__filters-category-container {
		@apply flex flex-nowrap gap-x-8 min-w-fit;
	}
}

@screen >=1440 {
	.d-calendar-list-custom__filters-category-container {
		padding-left: 54px;
		padding-right: 54px;
	}
}

.d-calendar-list-custom__filters-categories-inner--mobile {
	@apply w-full overflow-x-scroll;

	& > .d-calendar-list-custom__filters-category-container {
		@apply flex flex-col gap-y-8 min-w-fit;

		& > div {
			@apply flex flex-nowrap gap-x-8;
		}
	}
}

@screen >=768 {
	.d-calendar-list-custom__filters-categories-inner--mobile {
		@apply hidden;
	}
}

@screen <768 {
	.d-calendar-list-custom__filters-categories-inner--desktop {
		@apply hidden;
	}
}

.d-calendar-list-custom__filters-category {
	@apply flex flex-1 items-center gap-x-16 max-w-fit;
	@apply h-36 px-md bg-background rounded-full;
	@apply relative overflow-hidden min-w-max;
	@apply duration-300 ease-smooth-out;

	&:before {
		@apply absolute top-0 left-0 w-full h-full;
		@apply rounded-full bg-black bg-opacity-5;
		@apply duration-500 ease-smooth-out;
		@apply transform -translate-x-full;
		content: '';
		z-index: 0;
	}

	&:hover:before {
		@apply translate-x-0 bg-opacity-10;
	}

	& > * {
		@apply relative;
	}

	&.d-calendar-list-custom__filters-category--active,
	&:active {
		@apply bg-primary;

		&:before {
			@apply bg-secondary bg-opacity-10;
		}

		&:hover:before {
			@apply bg-opacity-20;
		}
	}

	& > svg {
		@apply w-24;
		max-width: 18px;
		max-height: 18px;
	}

	& > span {
		@apply whitespace-nowrap mb-4;
		@apply font-darker-grotesque font-semibold text-button-sm;
	}
}

@screen >=768 {
	.d-calendar-list-custom__filters-category {
		@apply h-52;

		& > span {
			@apply mb-6;
		}
	}
}

/**
	Category Gradient BUttons
 */
.d-calendar-list-custom__swiper-button {
	@apply absolute top-0 w-80 h-full pointer-events-none;
	@apply opacity-0 duration-150 ease-out;

	background: linear-gradient(
		90deg,
		#ffffff 35%,
		rgba(255, 255, 255, 0) 100%
	);

	&.d-calendar-list-custom__swiper-button--left {
		@apply left-0 transform -translate-x-16;
	}
	&.d-calendar-list-custom__swiper-button--right {
		@apply right-0 transform rotate-180 translate-x-16;
	}

	& .d-calendar-list-custom__swiper-button-inner {
		@apply absolute top-1/2 left-16 pointer-events-auto;
		@apply transform -translate-y-1/2 -translate-x-16;
		@apply w-32 h-32 rounded-full border-2 border-text bg-white;
		@apply flex justify-center items-center overflow-hidden;
		@apply duration-300 ease-out;

		& > svg {
			@apply transform rotate-90;
			@apply w-12 mr-2 pointer-events-none;

			& * {
				stroke-width: 5px;
			}
		}

		&:before {
			@apply absolute top-0 left-0;
			@apply w-full h-full rounded-full;
			@apply bg-text bg-opacity-5;
			@apply transform translate-x-full;
			@apply duration-300 ease-smooth-out;
			content: '';
		}

		&:hover:before {
			@apply translate-x-0 bg-opacity-10;
		}
	}

	&.active {
		@apply opacity-100 translate-x-0;

		& .d-calendar-list-custom__swiper-button-inner {
			@applt translate-x-0;
		}
	}
}

/**
	Results
 */
.d-calendar-list-custom__results {
	@apply mt-4xl;
}

.d-calendar-list-custom__results-indicator {
	@apply font-darker-grotesque font-medium text-h4;
	@apply w-3/5col;
}

@screen >=1024 {
	.d-calendar-list-custom__results-indicator {
		@apply w-full;
	}
}

.d-calendar-list-custom__results-cards {
	@apply pt-xl;
	@apply grid grid-cols-1 gap-layout-gutter;
}

@screen >=768 {
	.d-calendar-list-custom__results-cards {
		@apply grid-cols-2;
	}
}

@screen >=1024 {
	.d-calendar-list-custom__results-cards {
		@apply grid-cols-3;
	}
}

.d-calendar-list-custom__image-container {
	aspect-ratio: 416 / 234;

	& > img {
		@apply w-full h-full object-cover;
	}
}

/**
	Pages
 */
.d-calendar-list-custom__pages {
	@apply flex justify-center items-center mt-3xl;
	@apply font-darker-grotesque font-medium text-button;

	& > * {
		@apply relative;
		@apply w-32 h-32 rounded-full pb-4;
		@apply flex justify-center items-center;
		@apply overflow-hidden;
	}

	& > .d-calendar-list-custom__pages-spacer {
		@apply pb-14;
	}

	& > .d-calendar-list-custom__page--active {
		@apply bg-text text-white;
	}

	& > .d-calendar-list-custom__pages-prev--disabled,
	& > .d-calendar-list-custom__pages-next--disabled {
		@apply opacity-20;
	}

	& a:before {
		@apply bg-text bg-opacity-5;
		@apply absolute top-0 left-0 w-full h-full rounded-full;
		@apply duration-300 ease-smooth-out;
		@apply transform -translate-x-full;
		content: '';
	}

	& a:hover:before {
		@apply translate-x-0 bg-opacity-10;
	}

	& > .d-calendar-list-custom__pages-prev,
	& > .d-calendar-list-custom__pages-next {
		@apply border-2 border-text pb-0;

		& > svg {
			@apply transform;
			width: 13px;

			& > * {
				stroke-width: 5px;
			}
		}
	}
	& > .d-calendar-list-custom__pages-prev {
		@apply mr-auto;

		& svg {
			@apply rotate-90 -translate-x-1;
		}
	}
	& > .d-calendar-list-custom__pages-next {
		@apply ml-auto;

		& svg {
			@apply -rotate-90 translate-x-1;
		}
	}
}

@screen >=1024 {
	.d-calendar-list-custom__pages {
		@apply gap-x-10;

		& > * {
			@apply w-36 h-36;
		}

		& > .d-calendar-list-custom__pages-prev {
			@apply mr-lg;
		}

		& > .d-calendar-list-custom__pages-next {
			@apply ml-lg;
		}
	}
}

.d-calendar-list-custom__bottom {
	@apply relative;
}

.d-calendar-page-custom__kultunaut-logo {
	@apply w-140 mx-auto mt-64;
}

@screen >=1024 {
	.d-calendar-page-custom__kultunaut-logo {
		@apply absolute top-1/2 right-0 mt-0;
		@apply transform -translate-y-1/2;
	}
}
</style>
