<template>
	<div class="c-calendar-list-card">
		<!-- todo: replace # with link to event -->
		<a href="#" class="c-calendar-list-card__inner">
			<div class="c-calendar-list-card__image-container">
				<img
					src="https://dev-8481od-be.testserver.nu/media/kcsdidxl/skills-som-murer-2022.jpg"
				/>
			</div>

			<div class="c-calendar-list-card__content">
				<div>
					<!-- todo: replace below with correct category -->
					<div class="c-calendar-list-card__category">Natur</div>

					<!-- todo: replace below with correct title -->
					<div class="c-calendar-list-card__title">
						Geohunting - Ridder Ebbes Døtre - Højby
					</div>

					<div class="c-calendar-list-card__date">
						<svg
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask id="path-1-inside-1_46_55" fill="white">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.33333 3.66667C8.33333 3.29848 8.03486 3 7.66667 3C7.29848 3 7 3.29848 7 3.66667V8.33333C7 8.70152 7.29848 9 7.66667 9H12.3333C12.7015 9 13 8.70152 13 8.33333C13 7.96514 12.7015 7.66667 12.3333 7.66667H8.33333V3.66667Z"
								/>
							</mask>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.33333 3.66667C8.33333 3.29848 8.03486 3 7.66667 3C7.29848 3 7 3.29848 7 3.66667V8.33333C7 8.70152 7.29848 9 7.66667 9H12.3333C12.7015 9 13 8.70152 13 8.33333C13 7.96514 12.7015 7.66667 12.3333 7.66667H8.33333V3.66667Z"
								fill="currentColor"
							/>
							<path
								d="M8.33333 7.66667H7.33333V8.66667H8.33333V7.66667ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM1 8C1 4.13401 4.13401 1 8 1V-1C3.02944 -1 -1 3.02944 -1 8H1ZM8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM7.66667 4C7.48257 4 7.33333 3.85076 7.33333 3.66667H9.33333C9.33333 2.74619 8.58714 2 7.66667 2V4ZM8 3.66667C8 3.85076 7.85076 4 7.66667 4V2C6.74619 2 6 2.74619 6 3.66667H8ZM8 8.33333V3.66667H6V8.33333H8ZM7.66667 8C7.85076 8 8 8.14924 8 8.33333H6C6 9.25381 6.74619 10 7.66667 10V8ZM12.3333 8H7.66667V10H12.3333V8ZM12 8.33333C12 8.14924 12.1492 8 12.3333 8V10C13.2538 10 14 9.25381 14 8.33333H12ZM12.3333 8.66667C12.1492 8.66667 12 8.51743 12 8.33333H14C14 7.41286 13.2538 6.66667 12.3333 6.66667V8.66667ZM8.33333 8.66667H12.3333V6.66667H8.33333V8.66667ZM7.33333 3.66667V7.66667H9.33333V3.66667H7.33333Z"
								fill="currentColor"
								mask="url(#path-1-inside-1_46_55)"
							/>
						</svg>

						<!-- todo: replace below with correct date -->
						<span>Onsdag den 27. juli 2022, Kl. 18 -21.</span>
					</div>

					<div class="c-calendar-list-card__place">
						<svg
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8.8125 17.3571C8.43128 17.8838 8.43109 17.8837 8.43088 17.8835L8.43033 17.8832L8.42877 17.8821L8.42386 17.8787L8.40696 17.8671C8.39259 17.8572 8.3721 17.8429 8.34589 17.8244C8.29347 17.7874 8.21814 17.7334 8.12318 17.6632C7.93332 17.5229 7.66459 17.3176 7.34325 17.0534C6.70152 16.5259 5.84511 15.7601 4.987 14.8058C3.28701 12.9152 1.5 10.1957 1.5 7.07143C1.5 5.19597 2.27042 3.39732 3.64178 2.07117C5.01314 0.745023 6.8731 0 8.8125 0C10.7519 0 12.6119 0.745023 13.9832 2.07117C15.3546 3.39732 16.125 5.19597 16.125 7.07143C16.125 10.1957 14.338 12.9152 12.638 14.8058C11.7799 15.7601 10.9235 16.5259 10.2817 17.0534C9.96041 17.3176 9.69168 17.5229 9.50182 17.6632C9.40686 17.7334 9.33153 17.7874 9.27911 17.8244C9.2529 17.8429 9.23241 17.8572 9.21804 17.8671L9.20114 17.8787L9.19623 17.8821L9.19467 17.8832L9.19412 17.8835C9.19391 17.8837 9.19372 17.8838 8.8125 17.3571ZM8.8125 17.3571L9.19372 17.8838C8.96483 18.0387 8.66017 18.0387 8.43128 17.8838L8.8125 17.3571ZM8.8125 4.5C7.2592 4.5 6 5.7592 6 7.3125C6 8.8658 7.2592 10.125 8.8125 10.125C10.3658 10.125 11.625 8.8658 11.625 7.3125C11.625 5.7592 10.3658 4.5 8.8125 4.5Z"
								fill="currentColor"
							/>
						</svg>

						<!-- todo: replace below with correct place -->
						<span>Kajakoasen</span>
					</div>
				</div>

				<div class="c-calendar-list-card__read-more">
					<div>
						<svg
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.0016 8C12.179 8 9.06641 4.85827 9.06641 1"
								stroke="currentColor"
								stroke-width="2"
								stroke-miterlimit="10"
							/>
							<path
								d="M9.12109 15C9.12109 11.1417 12.2337 8 16.0562 8"
								stroke="currentColor"
								stroke-width="2"
								stroke-miterlimit="10"
							/>
							<path
								d="M14.9079 8L0 8"
								stroke="currentColor"
								stroke-width="2"
								stroke-miterlimit="10"
							/>
						</svg>
					</div>

					<span>Læs mere</span>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
export default {
	name: 'CalendarListCustomDemoCard',
};
</script>

<style lang="postcss">
.c-calendar-list-card__image-container {
	aspect-ratio: 416 / 234;

	& > img {
		@apply w-full h-full object-cover;
	}
}

.c-calendar-list-card {
	@apply duration-500 ease-smooth-out;
	@apply transform h-full;

	& .c-calendar-list-card__inner {
		@apply block bg-background transform h-full;
		@apply duration-500 ease-smooth-out;
		@apply flex flex-col;
	}

	&:hover {
		@apply -translate-x-5 -translate-y-5;
		@apply bg-primary bg-opacity-50;
	}

	&:hover .c-calendar-list-card__inner {
		@apply translate-x-10 translate-y-10;
		@apply bg-secondary bg-opacity-80;
	}

	& .c-calendar-list-card__content {
		@apply px-xl py-lg flex flex-col justify-between flex-1;

		& > div:first-child {
			@apply flex-1;
		}

		& .c-calendar-list-card__category {
			@apply font-darker-grotesque font-semibold text-label-card;
			@apply mb-6;
		}

		& .c-calendar-list-card__title {
			@apply font-darker-grotesque font-bold text-title-card;
			@apply mb-6;
		}

		& .c-calendar-list-card__date {
			@apply mt-24;
		}

		& .c-calendar-list-card__place {
			@apply mt-12;
		}

		& .c-calendar-list-card__date,
		& .c-calendar-list-card__place {
			@apply flex gap-x-18 text-teaser-sm;

			& > svg {
				@apply w-18;
			}
		}

		& .c-calendar-list-card__read-more {
			@apply font-darker-grotesque font-bold text-button-xs uppercase;
			@apply flex items-center gap-x-12 mt-lg;

			& > div {
				@apply flex justify-center items-center;
				@apply w-24 h-24 rounded-full bg-text text-white;

				& > svg {
					@apply w-12;

					& > * {
						stroke-width: 2;
					}
				}
			}

			& > span {
				@apply mb-2;
			}
		}
	}
}
</style>
