<template>
	<CalendarListCustomDemo v-if="demo" v-bind="{ ...$props, ...$attrs }" />
	<article v-else class="d-calendar-list-custom">
		<PageBreadcrumb :items="breadcrumb" class="mt-lg mb-4xl" />

		<div id="nautarrlist"></div>
		<PostScribeScript :html="script" />
	</article>
</template>

<script>
import CalendarListCustomDemo from './CalendarListCustomDemo.vue';

import PageBreadcrumb from '~/components/shared/PageBreadcrumb';
import PostScribeScript from '~/citi-baseline/components/PostScribeScript.vue';

export default {
	name: 'MainCalendarListCustom',
	components: { CalendarListCustomDemo, PostScribeScript, PageBreadcrumb },
	inheritAttrs: false,

	props: {
		breadcrumb: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			demo: this.$route.query?.hasOwnProperty('demo'),

			/* eslint-disable */
			script: `
				<script type="text/javascript">
					var naut = naut || {};
					naut.load = naut.load || [];
					naut.load.push(function () {
						naut.define({
							div: 'nautarrlist',
							tmpl: 'odsherred3',
							tmplid: 'arrlist',
						});
					});
					(function (d) {
						if (typeof naut.init === 'function') naut.init();
						var id = 'nautjs';
						if (d.getElementById(id)) {
							return;
						}
						var js = d.createElement('script');
						js.id = id;
						js.async = true;
						js.type = 'text/javascript';
						js.src = '//www.kultunaut.dk/nautjs.js';
						var node = d.getElementsByTagName('script')[0];
						node.parentNode.insertBefore(js, node);
					})(document);
				<\/script>
			`,
			/* eslint-enable */
		};
	},

	watch: {
		'$route.path'() {
			this.$nextTick(() => {
				this.addListeners();
			});
		},
	},

	mounted() {
		this.addListeners();
	},

	beforeDestroy() {
		const scrollers = document.querySelectorAll(
			'.d-calendar-list-custom__filters-categories-inner'
		);

		const buttons = document.querySelectorAll(
			'.d-calendar-list-custom__swiper-button-inner'
		);

		[...scrollers].forEach((scroller) => {
			scroller?.removeEventListener('scroll', this.onCategoriesScroll);
			scroller?.removeEventListener('resize', this.onCategoriesScroll);
		});

		[...buttons].forEach((button) => {
			button.removeEventListener('click', this.onCategoriesButtonClick);
		});
	},

	methods: {
		addListeners() {
			const scrollers = document.querySelectorAll(
				'.d-calendar-list-custom__filters-categories-inner'
			);

			const buttons = document.querySelectorAll(
				'.d-calendar-list-custom__swiper-button-inner'
			);

			[...scrollers].forEach((scroller) => {
				scroller?.addEventListener('scroll', this.onCategoriesScroll);
				scroller?.addEventListener('resize', this.onCategoriesScroll);
				this.onCategoriesScroll({ target: scroller });
			});

			[...buttons].forEach((button) => {
				button.addEventListener('click', this.onCategoriesButtonClick);
			});
		},

		onCategoriesScroll({ target }) {
			const modifier = window.innerWidth < 1024 ? 'mobile' : 'desktop';

			const left = document.querySelector(
				'.d-calendar-list-custom__swiper-button--left'
			);
			const right = document.querySelector(
				'.d-calendar-list-custom__swiper-button--right'
			);

			if (left && right) {
				const container = document.querySelector(
					`.d-calendar-list-custom__filters-categories-inner--${modifier} ` +
						'.d-calendar-list-custom__filters-category-container'
				);

				const { scrollLeft } = target;
				const { width: sw } = target.getBoundingClientRect();
				const { width: cw } = container.getBoundingClientRect();

				const canScrollLeft = scrollLeft > 4;
				const canScrollRight = scrollLeft + sw < cw - 4;

				left.classList[canScrollLeft ? 'add' : 'remove']('active');
				right.classList[canScrollRight ? 'add' : 'remove']('active');
			}
		},

		onCategoriesButtonClick({ target }) {
			const modifier = window.innerWidth < 1024 ? 'mobile' : 'desktop';
			const scroller = document.querySelector(
				`.d-calendar-list-custom__filters-categories-inner--${modifier}`
			);

			const left = target.classList.contains(
				'd-calendar-list-custom__swiper-button-inner--left'
			);

			scroller.scrollTo({
				left: scroller.scrollLeft + 220 * (left ? -1 : 1),
				behavior: 'smooth',
			});
		},
	},
};
</script>

<style lang="postcss">
/**
    General
 */
.d-calendar-list-custom :where(h1) {
	@apply font-darker-grotesque font-bold text-h1;
}

.d-calendar-list-custom :where(.d-calendar-list-custom__button) {
	@apply relative inline-flex items-center gap-x-16;
	@apply border-2 border-text rounded-full px-24 py-6;
	@apply font-darker-grotesque font-semibold text-label-card;
	@apply overflow-hidden;

	& > span {
		@apply mb-4;
	}

	& > svg {
		@apply w-12 text-text transform;
		@apply duration-500 ease-smooth-out;
	}

	&::before {
		@apply absolute top-0 left-0 w-full h-full;
		@apply rounded-full bg-black bg-opacity-5;
		@apply duration-500 ease-smooth-out;
		@apply transform -translate-x-full;
		z-index: -1;
		content: '';
	}

	&:hover::before {
		@apply translate-x-0 bg-opacity-10;
	}

	&:hover > svg {
		@apply translate-x-8;
	}
}

.d-calendar-list-custom :where(input[type='text']),
.d-calendar-list-custom :where(input[type='search']) {
	@apply rounded-full border-2 border-text;
	@apply font-darker-grotesque font-medium text-input;
	@apply h-48 px-lg pb-6;
}

@screen >=1024 {
	.d-calendar-list-custom :where(input[type='text']),
	.d-calendar-list-custom :where(input[type='search']) {
		@apply h-60;
	}
}

/**
    Content Specific
 */
.d-calendar-list-custom__contents {
	@apply mt-4xl;
}

.d-calendar-list-custom__top-title {
	@apply flex justify-between items-end;

	& > div {
		@apply space-x-12;
	}
}

/**
	Buttons
 */
.d-calendar-list-custom__top-buttons {
	display: none;
}

@screen >=1024 {
	.d-calendar-list-custom__top-buttons {
		display: block;
	}
}

.d-calendar-list-custom__bottom-buttons {
	@apply flex-col space-y-12 my-64;

	& > * {
		@apply w-full justify-between;
	}
}

@screen >=1024 {
	.d-calendar-list-custom__bottom-buttons {
		display: none;
	}
}

/**
	Search Filters
	General
 */
.d-calendar-list-custom__filters-general {
	@apply flex flex-col gap-x-layout-gutter gap-y-12 mt-2xl -mx-10;
}

@screen >=1024 {
	.d-calendar-list-custom__filters-general {
		@apply flex-row;
	}
}

.d-calendar-list-custom__filters-search {
	@apply w-full relative;

	& > input {
		@apply w-full;
	}

	& > button {
		@apply absolute top-1/2 right-lg w-16;
		@apply transform -translate-y-1/2;

		& > svg {
			@apply w-full;
		}
	}
}

@screen >=1024 {
	.d-calendar-list-custom__filters-search {
		@apply w-7/12col flex-shrink-0;
	}
}

.d-calendar-list-custom__filters-date {
	@apply w-full relative;

	& > .d-calendar-list-custom__filters-date-input {
		@apply flex items-center h-48 px-lg pb-6;
		@apply rounded-full border-2 border-text bg-text text-white;
		@apply font-darker-grotesque font-medium text-input-dropdown;
	}
}

@screen >=1024 {
	.d-calendar-list-custom__filters-date-input {
		@apply h-60 !important;
	}
}

/**
	Search Filters
	Categories
 */
.d-calendar-list-custom__filters-categories {
	@apply relative mt-md -mx-10;
}

@screen >=1440 {
	.d-calendar-list-custom__filters-categories {
		@apply -mx-64;
	}
}

.d-calendar-list-custom__filters-categories-inner--desktop {
	@apply w-full overflow-x-scroll;

	& > .d-calendar-list-custom__filters-category-container {
		@apply flex flex-nowrap gap-x-8 min-w-fit;
	}
}

@screen >=1440 {
	.d-calendar-list-custom__filters-category-container {
		padding-left: 54px;
		padding-right: 54px;
	}
}

.d-calendar-list-custom__filters-categories-inner--mobile {
	@apply w-full overflow-x-scroll;

	& > .d-calendar-list-custom__filters-category-container {
		@apply flex flex-col gap-y-8 min-w-fit;

		& > div {
			@apply flex flex-nowrap gap-x-8;
		}
	}
}

@screen >=768 {
	.d-calendar-list-custom__filters-categories-inner--mobile {
		@apply hidden;
	}
}

@screen <768 {
	.d-calendar-list-custom__filters-categories-inner--desktop {
		@apply hidden;
	}
}

.d-calendar-list-custom__filters-category {
	@apply flex flex-1 items-center gap-x-16 max-w-fit;
	@apply h-36 px-md bg-background rounded-full;
	@apply relative overflow-hidden min-w-max;
	@apply duration-300 ease-smooth-out;

	&:before {
		@apply absolute top-0 left-0 w-full h-full;
		@apply rounded-full bg-black bg-opacity-5;
		@apply duration-500 ease-smooth-out;
		@apply transform -translate-x-full;
		content: '';
		z-index: 0;
	}

	&:hover:before {
		@apply translate-x-0 bg-opacity-10;
	}

	& > * {
		@apply relative;
	}

	&.d-calendar-list-custom__filters-category--active,
	&:active {
		@apply bg-primary;

		&:before {
			@apply bg-secondary bg-opacity-10;
		}

		&:hover:before {
			@apply bg-opacity-20;
		}
	}

	& > svg {
		@apply w-24;
		max-width: 18px;
		max-height: 18px;
	}

	& > span {
		@apply whitespace-nowrap mb-4;
		@apply font-darker-grotesque font-semibold text-button-sm;
	}
}

@screen >=768 {
	.d-calendar-list-custom__filters-category {
		@apply h-52;

		& > span {
			@apply mb-6;
		}
	}
}

/**
	Category Gradient BUttons
 */
.d-calendar-list-custom__swiper-button {
	@apply absolute top-0 w-80 h-full pointer-events-none;
	@apply opacity-0 duration-150 ease-out;

	background: linear-gradient(
		90deg,
		#ffffff 35%,
		rgba(255, 255, 255, 0) 100%
	);

	&.d-calendar-list-custom__swiper-button--left {
		@apply left-0 transform -translate-x-16;
	}
	&.d-calendar-list-custom__swiper-button--right {
		@apply right-0 transform rotate-180 translate-x-16;
	}

	& .d-calendar-list-custom__swiper-button-inner {
		@apply absolute top-1/2 left-16 pointer-events-auto;
		@apply transform -translate-y-1/2 -translate-x-16;
		@apply w-32 h-32 rounded-full border-2 border-text bg-white;
		@apply flex justify-center items-center overflow-hidden;
		@apply duration-300 ease-out;

		& > svg {
			@apply transform rotate-90;
			@apply w-12 mr-2 pointer-events-none;

			& * {
				stroke-width: 5px;
			}
		}

		&:before {
			@apply absolute top-0 left-0;
			@apply w-full h-full rounded-full;
			@apply bg-text bg-opacity-5;
			@apply transform translate-x-full;
			@apply duration-300 ease-smooth-out;
			content: '';
		}

		&:hover:before {
			@apply translate-x-0 bg-opacity-10;
		}
	}

	&.active {
		@apply opacity-100 translate-x-0;

		& .d-calendar-list-custom__swiper-button-inner {
			@applt translate-x-0;
		}
	}
}

/**
	Results
 */
.d-calendar-list-custom__results {
	@apply mt-4xl;
}

.d-calendar-list-custom__results-indicator {
	@apply font-darker-grotesque font-medium text-h4;
	@apply w-3/5col;
}

@screen >=1024 {
	.d-calendar-list-custom__results-indicator {
		@apply w-full;
	}
}

.d-calendar-list-custom__results-cards {
	@apply pt-xl;
	@apply grid grid-cols-1 gap-layout-gutter;
}

@screen >=768 {
	.d-calendar-list-custom__results-cards {
		@apply grid-cols-2;
	}
}

@screen >=1024 {
	.d-calendar-list-custom__results-cards {
		@apply grid-cols-3;
	}
}

.d-calendar-list-custom__image-container {
	aspect-ratio: 416 / 234;

	& > img {
		@apply w-full h-full object-cover;
	}
}

/**
	Pages
 */
.d-calendar-list-custom__pages {
	@apply flex justify-center items-center mt-3xl;
	@apply font-darker-grotesque font-medium text-button;

	& > * {
		@apply relative;
		@apply w-32 h-32 rounded-full pb-4;
		@apply flex justify-center items-center;
		@apply overflow-hidden;
	}

	& > .d-calendar-list-custom__pages-spacer {
		@apply pb-14;
	}

	& > .d-calendar-list-custom__page--active {
		@apply bg-text text-white;
	}

	& > .d-calendar-list-custom__pages-prev--disabled,
	& > .d-calendar-list-custom__pages-next--disabled {
		@apply opacity-20;
	}

	& a:before {
		@apply bg-text bg-opacity-5;
		@apply absolute top-0 left-0 w-full h-full rounded-full;
		@apply duration-300 ease-smooth-out;
		@apply transform -translate-x-full;
		content: '';
	}

	& a:hover:before {
		@apply translate-x-0 bg-opacity-10;
	}

	& > .d-calendar-list-custom__pages-prev,
	& > .d-calendar-list-custom__pages-next {
		@apply border-2 border-text pb-0;

		& > svg {
			@apply transform;
			width: 13px;

			& > * {
				stroke-width: 5px;
			}
		}
	}
	& > .d-calendar-list-custom__pages-prev {
		@apply mr-auto;

		& svg {
			@apply rotate-90 -translate-x-1;
		}
	}
	& > .d-calendar-list-custom__pages-next {
		@apply ml-auto;

		& svg {
			@apply -rotate-90 translate-x-1;
		}
	}
}

@screen >=1024 {
	.d-calendar-list-custom__pages {
		@apply gap-x-10;

		& > * {
			@apply w-36 h-36;
		}

		& > .d-calendar-list-custom__pages-prev {
			@apply mr-lg;
		}

		& > .d-calendar-list-custom__pages-next {
			@apply ml-lg;
		}
	}
}

.d-calendar-list-custom__bottom {
	@apply relative;
}

.d-calendar-page-custom__kultunaut-logo {
	@apply w-140 mx-auto mt-64;
}

@screen >=1024 {
	.d-calendar-page-custom__kultunaut-logo {
		@apply absolute top-1/2 right-0 mt-0;
		@apply transform -translate-y-1/2;
	}
}

/**
	Card
 */
.c-calendar-list-card__image-container {
	aspect-ratio: 416 / 234;

	& > img {
		@apply w-full h-full object-cover;
	}
}

.c-calendar-list-card {
	@apply duration-500 ease-smooth-out;
	@apply transform h-full;

	& .c-calendar-list-card__inner {
		@apply block bg-background transform h-full;
		@apply duration-500 ease-smooth-out;
		@apply flex flex-col;
	}

	&:hover {
		@apply -translate-x-5 -translate-y-5;
		@apply bg-primary bg-opacity-50;
	}

	&:hover .c-calendar-list-card__inner {
		@apply translate-x-10 translate-y-10;
		@apply bg-secondary bg-opacity-80;
	}

	& .c-calendar-list-card__content {
		@apply px-xl py-lg flex flex-col justify-between flex-1;

		& > div:first-child {
			@apply flex-1;
		}

		& .c-calendar-list-card__category {
			@apply font-darker-grotesque font-semibold text-label-card;
			@apply mb-6;
		}

		& .c-calendar-list-card__title {
			@apply font-darker-grotesque font-bold text-title-card;
			@apply mb-6;
		}

		& .c-calendar-list-card__date {
			@apply mt-24;
		}

		& .c-calendar-list-card__place {
			@apply mt-12;
		}

		& .c-calendar-list-card__date,
		& .c-calendar-list-card__place {
			@apply flex gap-x-18 text-teaser-sm;

			& > svg {
				@apply w-18;
			}
		}

		& .c-calendar-list-card__read-more {
			@apply font-darker-grotesque font-bold text-button-xs uppercase;
			@apply flex items-center gap-x-12 mt-lg;

			& > div {
				@apply flex justify-center items-center;
				@apply w-24 h-24 rounded-full bg-text text-white;

				& > svg {
					@apply w-12;

					& > * {
						stroke-width: 2;
					}
				}
			}

			& > span {
				@apply mb-2;
			}
		}
	}
}
</style>
